<template>
  <app-container
    :title="payResult.actionType == 1 ? '套餐充值结果' : payResult.actionType == 2 ? '余额充值结果' : '支付结果'"
    class="pay-result"
    :background="tool.getThemeList(theme)"
    :showIcon="false"
  >
    <div class="pay-main">
      <template v-if="payResult.isTongLian">
        <div class="pay-head pay-head2 textCenter">
          <van-icon name="checked" :color="tool.getThemeList(theme)" size="40" />
          <p class="pay-status2">支付成功</p>
        </div>
        <van-button class="pay-button" type="primary" block @click="jumpOut">返回</van-button>
      </template>
      <template v-else>
        <div class="pay-head textCenter">
          <van-icon name="checked" :color="tool.getThemeList(theme)" size="96" />
          <p class="pay-status1">支付成功</p>
          <h1 style="font-weight: 200;">¥{{ payResult.userRechargeAmount || Number(payResult.money || 0) }}</h1>
          <div class="card_box">
            <div class="left">
              卡 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号: {{ payResult.card }}
            </div>
          </div>
          <div
            class="packageName_box"
            v-if="payResult.hasOwnProperty('packageName')"
          >
            <div class="left">套餐名称:</div>
            <div class="right">
              {{ payResult.packageName }}
            </div>
          </div>
        </div>
        <van-button
          class="pay-button"
          type="primary"
          block
          @click="add()"
          v-if="payResult.actionType == 1"
          >再充值一笔</van-button
        >
        <van-button class="pay-button" type="primary" block @click="add_()" v-else
          >去充值套餐</van-button
        >
        <van-button
          class="return-button"
          type="primary"
          color="#666"
          plain
          block
          @click="returnPage"
          >返回</van-button
        >
        <div class="pay-tips" v-if="payResult.actionType == 1">
          <h2>温馨提示:</h2>
          <p>卡片使用过程中，建议不要更换设备使用避免卡片被锁定无法上网</p>
          <p class="tips-word">
            {{ payResult.desc }}
          </p>
        </div>
        <div class="pay-tips" v-if="payResult.actionType == 2">
          <h2>温馨提示:</h2>
          <p class="tips-word">
            {{ payResult.desc }}
          </p>
        </div>
      </template>
    </div>
    <!-- <div class="ad-position">
      <van-divider>活动</van-divider>
      <a href="#" class="ad-bottom"></a>
    </div> -->
  </app-container>
</template>


<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from "vue";
import { useRoute } from "vue-router";
import { removeStore } from "@/utils/store";
import { trackSubmit, handleToLowerCase } from  '@/utils/track';

export default {
  setup() {
    const state = reactive({
      count: 0,
      payResult: {},
      theme: "" //主题名称
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    Object.assign(state.payResult, route.query);
    const methods = {
      jumpOut(){
        let jumpOutUrl = location.origin + `/query?iccid=${state.payResult.card}`
        let mchData ={action:'jumpOut', jumpOutUrl}
        let postData = JSON. stringify(mchData)
        parent.postMessage(postData,'https://payapp.weixin.qq.com')
        removeStore({ name: "tongLianInfo"});
      },
      //套餐详情 再充值套餐
      add() {
        if(state.theme === 'YuYin'){
          router.push({
            name: "yy-platfrom"
          });
          return 
        }
        if(state.theme === 'AnFang'){
          router.push({
            name: "af-platfrom"
          });
          return 
        }
        //流量套餐 再充值套餐
        if (
          state.payResult.activeType == 0 &&
          state.payResult.orderType === "ordinaryOrder"
        ) {
          router.push({
            name: "personal-packageRecharge",
            query: {
              active: 0
            }
          });
        }
        //短信套餐 再充值套餐
        if (
          state.payResult.activeType == 2 &&
          state.payResult.orderType === "ordinaryOrder"
        ) {
          router.push({
            name: "personal-packageRecharge",
            query: {
              active: 2
            }
          });
        }
        //停机保号套餐 再充值套餐
        if (state.payResult.orderType === "stopOrder") {
          router.push({
            name: "stop-recharge"
          });
        }
      },
      //余额充值  去充值套餐
      add_() {
        if(state.theme === 'YuYin'){
          router.push({
            name: "yy-platfrom"
          });
          return 
        }
        if(state.theme === 'AnFang'){
          router.push({
            name: "af-platfrom"
          });
          return 
        }
        if (state.payResult.activeType == 0) {
          router.push({
            name: "personal-packageRecharge",
            query: {
              active: 0
            }
          });
        }
        if (state.payResult.orderType === "stopOrder") {
          router.push({
            name: "stop-recharge"
          });
        }
        if (state.payResult.activeType == 2) {
          router.push({
            name: "personal-packageRecharge",
            query: {
              active: 2
            }
          });
        }
      },
      //返回
      returnPage() {
        if(state.theme === 'YuYin'){
          router.push({
            name: "yy-platfrom"
          });
          return 
        }
        if(state.theme === 'AnFang'){
          router.push({
            name: "af-platfrom"
          });
          return 
        }
        switch (Number(state.payResult.actionType)) {
          case 1:
            router.push({
              name: "personal"
            });
            break;
          case 2:
            router.push({
              name: "personal-packageRecharge",
              query: {
                active: 1
              }
            });
            break;
          default:
            break;
        }
      }
    };
    onMounted(() => {
      //获取主题名称
      state.theme = store.state.common.theme;
      let tongLianInfo = store.state.user.tongLianInfo
      Object.assign(state.payResult,tongLianInfo)
			trackSubmit({eventCode: handleToLowerCase(state.theme)+'_recharge_done_page'})
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.textCenter {
  text-align: center;
}
.van-button + .van-button {
  margin-top: 20px;
}
.pay-button,
.return-button {
  border-radius: 20px;
}
.pay-button {
  @include background_color("background_color1");
  border: none;
}
.return-button {
  width: 690px;
  height: 84px;
  border: 1px solid #aaa !important;
}
.tips-word {
  font-size: 26px;
  color: #aaa;
}
.pay- {
  &result {
    background-color: #fff;
  }
  &main {
    height: 82vh;
    padding: 10px 30px 0;
    .pay-head {
      margin-bottom: 60px;
      p {
        height: 36px;
        line-height: 36px;
        margin: 20px 0;
        &.pay-status1 {
          @include font_color("font_color1");
          margin-top: 0;
        }
        &.pay-status2{
         @include font_color("font_color1");
        }
      }
      .card_box {
        display: flex;
        margin-top: 40px;
        .left {
          width: 690px;
          text-align: left;
        }
      }
      .packageName_box {
        margin-top: 20px;
        display: flex;
        .left {
          text-align: left;
        }
        .right {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          color: #000;
          width: 69vw;
          white-space: nowrap;
          overflow-x: auto;
        }
      }
    }
    .pay-head2{
      margin: 120px auto;
    }
  }
  &tips {
    margin-top: 20px;
    h2 {
      font-size: 30px;
      color: red;
    }
    p {
      font-size: 26px;
      color: red;
      margin-top: 16px;
    }
  }
}

.ad-position {
  position: fixed;
  bottom: 0;
  width: 100%;
  .van-divider {
    padding: 0 30px;
  }
  .ad-bottom {
    display: block;
    width: 100%;
    height: 163px;
    background: url("~_ASSETS_/image/payResult/pay01.png") center/cover
      no-repeat;
  }
}
</style>
